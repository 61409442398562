import React, { useState } from "react";
import styled from "@emotion/styled";

import { useEffectOnce } from "../../hooks/useEffectOnce";
import { params } from "../../../utils/params";

import SearchBar from "../../molecules/SearchBar";
import SearchList from "../../molecules/SearchList";

function Search({
  lists,
  placeholder,
  onSearchChange,
  onSubmit,
  onListClick,
  valueKey,
  ...props
}) {
  const [searchedCode, setSearchedCode] = useState(""); //검색 입력했을때 검색 리스트 저장
  const [selectedCode, setSelectedCode] = useState(""); //검색 눌렀을때 저장

  const isCompletedSearch = searchedCode === selectedCode;

  useEffectOnce(() => {
    if (params.code) {
      setSelectedCode(params.code);
    }
  });

  return (
    <Wrapper {...props}>
      <StyledSearchBar
        onSearchChange={(code) => {
          if (onSearchChange) {
            onSearchChange(code);
          }
          setSearchedCode(code);
        }}
        //연관 검색어 리스트 활성화
        disabled={!onSearchChange}
        placeholder={placeholder}
        onSubmit={(e) => {
          onSubmit(e);
          setSelectedCode(e);
        }}
        value={searchedCode}
      />
      {searchedCode && !isCompletedSearch && (
        <SearchList
          code={searchedCode}
          lists={lists}
          onClick={(data) => {
            onListClick(data);
            setSelectedCode(data[valueKey]);
            setSearchedCode(data[valueKey]);
          }}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const StyledSearchBar = styled(SearchBar)`
  margin-bottom: 16px;
`;

export default Search;
