import axios from "axios";
import { useEffect } from "react";
import { endpoint } from "../../api";

const customAxios = axios.create({
  baseURL: endpoint,
});

const useAxios = () => {
  const accessToken = localStorage.getItem("accessToken");
  const setAccessToken = (token) => {
    localStorage.setItem("accessToken", token);
  };

  const refreshToken = localStorage.getItem("refreshToken");
  const setRefreshToken = (token) => {
    localStorage.setItem("refreshToken", token);
  };

  useEffect(() => {
    const interceptor = customAxios.interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          ...config.headers,
        },
      };
    });
    return () => {
      customAxios.interceptors.request.eject(interceptor);
    };
  }, [accessToken]);

  useEffect(() => {
    const interceptor = customAxios.interceptors.response.use(
      (res) => res,
      async (err) => {
        if (err.response?.status !== 401) return Promise.reject(err);

        try {
          const res = await axios.post("/barcode/auth/refresh", {
            refreshToken,
          });
          setAccessToken(res.data.access_token);
          setRefreshToken(res.data.refresh_token);
          err.config.headers[
            "Authorization"
          ] = `Bearer ${res.data.access_token}`;
          return await axios(err.config);
        } catch (err) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("authUser");

          return Promise.reject(err);
        }
      }
    );
    return () => {
      customAxios.interceptors.response.eject(interceptor);
    };
  }, [refreshToken, setAccessToken]);

  return customAxios;
};

export default useAxios;
