import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "../components/hooks/useEffectOnce";

import useSWR from "swr";
import { customAxios } from "../api";
import { params } from "../utils/params";

import MainTemplate from "../components/templates/MainTemplate";
import Header from "../components/organisms/Header";
import Footer from "../components/organisms/Footer";
import Search from "../components/organisms/Search";
import MainContent from "../components/organisms/MainContent";

function Home(props) {
  const navigate = useNavigate();
  const [productCode, setProductCode] = useState("");
  const [productList, setProductList] = useState("");
  const [searchedProduct, setSearchedProduct] = useState(null);
  const { data: productsData } = useSWR(
    `/barcode/search?search=${productCode}`
  );
  const products = productsData?.products;

  const isSoldOut = searchedProduct?.is_sale === 1;

  useEffect(() => {
    if (products) {
      setProductList(products);
    }
  }, [products]);

  async function onSubmit(code) {
    try {
      if (!code) return;
      const { data } = await customAxios.get(
        `/barcode/product?sku_code=${code}`
      );
      if (!data)
        return window.alert("입력하신 코드와 일치하는 상품이 없습니다.");

      setProductCode(code);
      setSearchedProduct(data.data.product);
    } catch (e) {
      console.log(e);
    }
  }

  useEffectOnce(() => {
    if (params.code) {
      onSubmit(params.code);
    }
  });

  function handleLogout() {
    localStorage.removeItem("authUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("level");
    window.location.reload();
  }

  const listData =
    productList &&
    productList?.map((list, i) => {
      const { sku_code, brand_eng, product_name, serial_no } = list;

      return {
        name: `${sku_code} | ${brand_eng} | ${product_name} (${serial_no})`,
        data: list,
      };
    });

  const isModify = () => {
    const level = localStorage.getItem("level");
    return parseInt(level) > 2;
  };

  useEffect(() => {
    const level = localStorage.getItem("level");
  }, []);

  return (
    <MainTemplate
      header={
        <Header>
          <Header.Center text="상품조회" />
          <Header.Right onClick={handleLogout}>
            <Header.Icon width={40} icon="logout" />
          </Header.Right>
        </Header>
      }
      footer={
        <Footer>
          <Footer.Button
            color="secondary"
            type="button"
            onClick={(e) => navigate(`/editing/${searchedProduct.sku_code}`)}
            disabled={
              isSoldOut ||
              searchedProduct === null ||
              !productCode ||
              !isModify()
            }
          >
            수정
          </Footer.Button>
          <Footer.Button
            color="primary"
            type="button"
            onClick={(e) => navigate(`/selling/${searchedProduct.sku_code}`)}
            disabled={isSoldOut || searchedProduct === null || !productCode}
          >
            판매
          </Footer.Button>
        </Footer>
      }
      contentHeader={true}
    >
      <Search
        placeholder="SKU 상품코드"
        onSearchChange={(code) => setProductCode(code)}
        onSubmit={onSubmit}
        lists={listData}
        onListClick={(e) => onSubmit(e.sku_code)}
        valueKey="sku_code"
      />

      <MainContent data={searchedProduct} />
    </MainTemplate>
  );
}

export default Home;
