import React from 'react';
import styled from "@emotion/styled";

import Button from "../../atoms/Button";

function Footer({ children, ...props }) {

    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`;

Footer.Button = Button

export default Footer;