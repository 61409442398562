import { Navigate, useLocation } from "react-router-dom";
import useSWR from "swr";
import React, { useState, useEffect } from "react";

const AuthMiddleware = ({ redirectPath = "/login", element: Component }) => {
  const location = useLocation();
  const isAuth =
    localStorage.getItem("authUser") && localStorage.getItem("accessToken");

  if (!isAuth) {
    return <Navigate to={redirectPath} state={location.pathname} replace />;
  } else {
    return <Component />;
  }
};

export default AuthMiddleware;
