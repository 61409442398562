import React, { useState } from "react";
import styled from "@emotion/styled";

import Input from "../../atoms/Input";
import Icon from "../../atoms/Icon";

import emptyImage from "../../../assets/images/empty.png";

function MainContent({ data, ...props }) {
  let product = {
    is_sale: 0,
    branch_name: "",
    brand_eng: "",
    product_name: "",
    serial_no: "",
    price: "",
    event_price: "",
    product_location: "",
    images: [],
  };

  if (data) {
    product = data;
  }

  const {
    is_sale,
    branch_name,
    brand_eng,
    product_name,
    serial_no,
    price,
    event_price,
    product_location,
    images,
  } = product;
  const productName = `${brand_eng} | ${product_name} (${serial_no})`;

  const isSoldOut = (
    <SoldOutWrapper>
      <Icon className="icon" width={60} icon="no-data" />
      <span>해당상품은 품절된 상품입니다.</span>
    </SoldOutWrapper>
  );

  const defaultProductImages = (
    <div>
      <img src={emptyImage} width={120} height={120} />
      <img src={emptyImage} width={120} height={120} />
      <img src={emptyImage} width={120} height={120} />
      <img src={emptyImage} width={120} height={120} />
    </div>
  );

  return (
    <Wrapper {...props}>
      <Input disabled={true} placeholder="지점" value={branch_name} />
      <Input
        disabled={true}
        placeholder="상품명"
        value={data ? productName : ""}
      />

      {is_sale === 0 ? (
        <React.Fragment>
          <InputWrapper>
            <span>판매가</span>
            <Input
              disabled={true}
              placeholder="판매가"
              value={!price || price === 0 ? "" : price.format()}
            />
          </InputWrapper>
          <InputWrapper>
            <span>행사가</span>
            <Input
              disabled={true}
              placeholder="행사가"
              value={
                !event_price
                  ? ""
                  : event_price === 0
                  ? "미설정"
                  : event_price.format()
              }
            />{" "}
          </InputWrapper>
          <Input
            className="last"
            disabled={true}
            placeholder="재고위치"
            value={product_location}
          />

          <ImageWrapper>
            <h1>사진</h1>
            {data ? (
              <div>
                {images &&
                  images.map((image, i) => (
                    <img
                      key={i}
                      src={image.image_url}
                      width={120}
                      height={120}
                    />
                  ))}
              </div>
            ) : (
              defaultProductImages
            )}
          </ImageWrapper>
        </React.Fragment>
      ) : (
        isSoldOut
      )}
    </Wrapper>
  );
}

const InputWrapper = styled.div`
  position: relative;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;

    position: absolute;
    top: 13px;
    left: 12px;
  }

  input {
    padding-left: 80px;
  }
`;

const Wrapper = styled.div`
  display: grid;

  input {
    margin-bottom: 16px;

    color: ${({ theme }) => theme.fg.black};

    &.last {
      margin-bottom: 24px;
    }
  }
`;

const ImageWrapper = styled.div`
  display: grid;
  h1 {
    text-align: start;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${({ theme }) => theme.fg.black};
    margin-bottom: 8px;
  }
  div {
    overflow: scroll;

    display: flex;
    gap: 8px;
  }
`;

const SoldOutWrapper = styled.div`
  padding: 24px;
  display: grid;

  .icon {
    margin: 0 auto 16px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.fg.gray};
  }
`;

export default MainContent;
