import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import barcodeImg from "../../assets/images/barcode.png";

function MainTemplate({
  header,
  footer,
  contentHeader,
  hideBarcode,
  children,
  ...props
}) {
  const ref = useRef(null);

  const [isCheckedScrollTop, setIsCheckedScrollTop] = useState(false);

  function handleScroll(e) {
    const target = e.target;
    const { scrollTop } = target;
    if (scrollTop === 0 || scrollTop === 2) {
      target.scrollTo(0, 1);
      setIsCheckedScrollTop(true);
    }
  }

  useEffect(() => {
    const current = ref.current;
    if (current && !isCheckedScrollTop) {
      current.addEventListener("scroll", handleScroll);

      return () => {
        try {
          current.removeEventListener("scroll", handleScroll);
        } catch (e) {
          console.log(e);
        }
      };
    }
  }, [ref]);

  const navigate = useNavigate();
  return (
    <Container ref={ref} {...props}>
      <Header>{header}</Header>
      <Content>
        {contentHeader && (
          <ContentHeader>
            <h1>Barcode</h1>
            {!hideBarcode && (
              <img onClick={() => navigate("/barcode")} src={barcodeImg} />
            )}
          </ContentHeader>
        )}

        {children}
      </Content>
      <Footer>{footer}</Footer>
      <div id="make-scrollable"></div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100);

  #make-scrollable {
    top: 0;
    position: absolute;
    left: 0;
    width: 1px;
    height: calc(
      100% + 2px
    ); // height를 100%보다 1px높게 잡아 실제로 scroll이 되도록 만듭니다.
  }
`;

const Header = styled.header`
  background: ${({ theme }) => theme.bg.primary};
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% + 82px);
  overflow-y: scroll;
  text-align: center;
  position: relative;

  padding: 16px 24px;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${({ theme }) => theme.fg.primary};
    font-weight: 700;
    font-size: 28px;
    line-height: 160%;
  }
  margin-bottom: 16px;
`;

const Footer = styled.footer`
  background-color: ${({ theme }) => theme.fg.white};

  height: 52px;
  display: flex;
  overflow: hidden;
  padding: 15px 24px;
  box-sizing: content-box;
  //height: 82px;
  //padding: 15px 0;
  //position: fixed;
  //bottom: 0;
  //left: 24px;
  //right: 24px;
`;

export default MainTemplate;
