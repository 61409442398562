import React from 'react';
import styled from "@emotion/styled";

import Input from "../../atoms/Input";

function Radio({id, ...props}) {
    return (
        <Wrapper>
            <Check id={id} type="radio" {...props}/>
            <label htmlFor={id}></label>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  border: ${({theme}) => `1px solid ${theme.fg.translucent}`};
  border-radius: 50px;
  margin: 5px;
  overflow: hidden;
  position: relative;
  
  label {
    position: absolute;
    background: white;
    border-radius: 50px;
    width: 8px;
    height: 8px;
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    margin: auto;
  }
`;

const Check = styled(Input)`
  margin: 0 !important;
  padding: 0;
  appearance: none;

  width: 18px !important;
  height: 18px !important;

  text-align: center;

  &:checked {
    background-color: ${({theme}) => theme.fg.secondary};

    ::after {
      font-weight: 700;
      color: beige;
    }
  }
`;

export default Radio;