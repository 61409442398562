import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

const errorAni = keyframes`
  0% {-webkit-transform: scale(1);}
  10%, 20% {-webkit-transform: scale(1) rotate(-3deg);}
  30%, 50%, 70%, 90% {-webkit-transform: scale(1) rotate(3deg);}
  40%, 60%, 80% {-webkit-transform: scale(1) rotate(-3deg);}
  100% {-webkit-transform: scale(1) rotate(0);}
`;

const Input = ({ ...props }) => {
  const { type } = props;

  function blockText(e) {
    if(type === "comma") {
      let key = e.key;
      let regex = /[0-9]|\./;
      if( !regex.test(key) ) {
        if(e.key !== "Backspace") {
          e.preventDefault();
        };
      }
    }
  };

  function addComma(e) {
    if(type === "comma") {
      let value = e.target.value;
      value = Number(value.replaceAll(',', ''));
      if(isNaN(value)) {
        return;
      }else {
        const formatValue = value.toLocaleString('ko-KR');
        e.target.value = formatValue;
      }
    }
  }

  if (type === "textarea") {
    return <StyledTextarea {...props} />;
  }
  if (type === "select") {
    return <StyledSelect {...props} />;
  }
  return <StyledInput
      onKeyUp={addComma}
      onKeyDown={blockText}
      {...props}
  />;
};

const styles = (props) => css`
  display: block;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;

  height: 52px;

  // animation: ${props.invalid ? errorAni : ""} 1.5s ease infinite;


  padding: 13px 12px;
  color: ${props.invalid ? props.theme.fg.danger : props.disabled ? props.theme.fg.translucent : props.theme.fg.black};
  
  
  background-color: ${props.disabled && "#F2F2F2"};
  border: 1px solid ${props.invalid
    ? props.theme.fg.danger
    : props.theme.bg.translucent};
  transition: color 250ms ease-out, border-color 250ms ease-out;
  border-radius: 8px;
  
  ::placeholder {
    transition: color 250ms ease-out;
    color: ${props.invalid ? props.theme.fg.danger : props.theme.fg.translucent};
  }

  &[type="checkbox"],
  &[type="radio"] {
    display: inline-block;
    border: 0;
    border-radius: 0;
    width: auto;
    height: auto;
    margin: 0 0.2rem 0 0;
  }
`;
const StyledTextarea = styled.textarea`
  ${styles}
`;
const StyledSelect = styled.select`
  ${styles}
`;
const StyledInput = styled.input`
  ${styles}
`;

Input.propTypes = {
  type: PropTypes.string,
  invalid: PropTypes.bool,
};

Input.defaultProps = {
  type: "text",
};

export default Input;
