import React from "react";
import styled from "@emotion/styled";

import Input from "../../atoms/Input";
import Select from "../Select";

function EditingContent({
  data,
  locationLists,
  onInputChange,
  inputValue,
  onSelectChange,
  selectValue,
  ...props
}) {
  let product = {
    is_sale: 0,
    branch_name: "",
    brand_eng: "",
    product_name: "",
    serial_no: "",
    price: "",
    event_price: "",
    product_location: "",
    images: [],
  };

  if (data) {
    product = data;
  }

  const {
    is_sale,
    branch_name,
    brand_eng,
    product_name,
    serial_no,
    price,
    event_price,
    product_location,
    images,
  } = product;
  const productName = `${brand_eng} | ${product_name} (${serial_no})`;

  const selectData = locationLists?.map((location, i) => ({
    id: location.text,
    name: location.value,
  }));

  return (
    <Wrapper {...props}>
      <Input disabled={true} placeholder="지점" value={branch_name} />
      <Input
        disabled={true}
        placeholder="상품명"
        value={data ? productName : ""}
      />

      <Input disabled={true} placeholder="판매가" value={price.format()} />

      <InputWrapper>
        <span>행사가</span>
        <Input type="comma" value={inputValue} onChange={onInputChange} />
      </InputWrapper>

      <Select
        lists={selectData}
        defaultValue={product_location}
        onChange={onSelectChange}
        value={selectValue}
      />

      <ImageWrapper>
        <h1>사진</h1>
        <div>
          {images &&
            images.map((image, i) => (
              <img key={i} src={image.image_url} width={120} height={120} />
            ))}
        </div>
      </ImageWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;

  input {
    margin-bottom: 16px;

    &.last {
      margin-bottom: 24px;
    }
  }
`;

const ImageWrapper = styled.div`
  display: grid;
  h1 {
    text-align: start;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${({ theme }) => theme.fg.black};
    margin-bottom: 8px;
  }
  div {
    overflow: scroll;

    display: flex;
    gap: 8px;
  }
  img {
    opacity: 0.6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;

    position: absolute;
    top: 13px;
    left: 12px;
  }

  input {
    padding-left: 80px;
  }
`;

export default EditingContent;
