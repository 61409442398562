import { Route, Routes } from "react-router-dom";
import { authRoutes, unAuthRoutes } from "./routes/router";
import AuthMiddleware from "./routes/AuthMiddleware";
import { useEffect, useRef, useState } from "react";

const App = () => {
  const interval = useRef(null);
  const [logoutTime, setLogoutTime] = useState(null);

  function setDateTime(item,type,unit){
    let setDate = "";
    if(item){
      let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
      let date = new Date(basicDate),
        yy,
        mm,
        dd,
        th,
        tm,
        ts;
  
      yy = date.getFullYear();
      mm = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      dd = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
      th = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
      tm = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
      ts = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();
      
      if(type == "yymmdd"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일";
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd;
        }else if(unit == "not"){
          setDate = `${yy}${mm}${dd}`;
        }else{
          setDate = yy + "." + mm + "." + dd;
        }
      }else if(type == "yymmddhhtt"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm;
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm;
        }else{
          setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm;
        }
      }else if(type == "mmdd"){
        if(unit == "ymd"){
          setDate = mm + "월 " + dd + "일";
        }else if(unit == "hymd"){
          setDate = mm + "-" + dd;
        }else if(unit == "slash"){
          setDate = mm + "/" + dd;
        }else{
          setDate = mm + "." + dd;
        }
      }else if(type == "yymm"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월";
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm;
        }else if(unit == "slash"){
          setDate = yy + "/" + mm;
        }else{
          setDate = yy + "." + mm;
        }
      }else if(type == "day"){
        setDate = dd;
      }else if(type == "yymmddhhttss"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm + ":" + ts;
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm + ":" + ts;
        }else{
          setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
        }
      }else if(type == "hhttss"){
        setDate = th + ":" + tm + ":" + ts;
      }else if(type == "hhtt"){
        setDate = th + ":" + tm;
      }else{
        setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
      }
    }
    return setDate;
  }

  function logoutTimeSetting(){
    fetch(`${process.env.REACT_APP_API_END_POINT}/manager/setting/logout`, {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("accessToken"),
          'Accept' : 'application/json'
      },
    }).then(function(response) {
        return response.json();
    }).then(function(fetchRd) {
        if (fetchRd.success == true) {
            setLogoutTime(fetchRd?.data?.logout_time);
        }else{
            
        }
    });
  }

  useEffect(() => {
    let nowTime = new Date();
    if(logoutTime || logoutTime === 0){
      nowTime.setMinutes(nowTime.getMinutes() + Number(logoutTime));
      localStorage.setItem("logoutTime", setDateTime(nowTime,"yymmddhhttss","hymd"));
    }
  }, [logoutTime]);

  useEffect(() => {
    if(localStorage.getItem("accessToken") && !localStorage.getItem("logoutTime")){
      logoutTimeSetting();
    }else if(localStorage.getItem("logoutTime")){
      interval.current = setInterval(function() {
          let chkTime = new Date();
          let logoutTimeSet = String(localStorage.getItem("logoutTime")).indexOf("-") > 0 || String(localStorage.getItem("logoutTime")).indexOf(".") > 0 ? String(localStorage.getItem("logoutTime")).replace(/-\./g,"/") : localStorage.getItem("logoutTime");
          let logoutTime = new Date(logoutTimeSet);

          if(chkTime >= logoutTime){
            clearInterval(interval.current);
            localStorage.removeItem("logoutTime");

            localStorage.clear();

            localStorage.setItem("logoutAll",true);

            window.location.href = "/login";
            window.location.reload(true);
          }
      }, 1000);
    }

    return () => {
        clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      if(!localStorage.getItem("accessToken") && localStorage.getItem("logoutAll")){
        localStorage.removeItem("logoutAll");
        window.location.href = "/login";
        window.location.reload(true);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <Routes>
      {unAuthRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}

      {authRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<AuthMiddleware element={route.element} />}
        />
      ))}
    </Routes>
  );
};

export default App;
