import styled from '@emotion/styled';
import {keyframes} from "@emotion/react";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Spinner = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 0.2em dashed black;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  animation: ${spin} 2s linear infinite;
`




export default Spinner