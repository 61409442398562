import axios from "axios";

export const endpoint = process.env.REACT_APP_API_END_POINT;

const accessToken = localStorage.getItem("accessToken");
const setAccessToken = (token) => {
  localStorage.setItem("accessToken", token);
};

const refreshToken = localStorage.getItem("refreshToken");
const setRefreshToken = (token) => {
  localStorage.setItem("refreshToken", token);
};

export const customAxios = axios.create({
  baseURL: endpoint,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

customAxios.interceptors.response.use(
  (result) => {
    return new Promise((resolve, reject) => {
      resolve(result);
    });
  },
  async (error) => {
    if (error.response?.status !== 401) return Promise.resolve(error);
    try {
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);
