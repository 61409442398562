import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Icon from "../../atoms/Icon";

const Header = ({ children, ...props }) => {
    return <Wrapper {...props}>{children}</Wrapper>;
};

const Left = ({ title, active, icon, children, ...props }) => {
    if (title) {
        return (
            <LeftWrapper {...props}>
                <Title>{title}</Title>
                {children}
            </LeftWrapper>
        );
    }
    return (
        <LeftWrapper {...props} style={{ cursor: "pointer" }}>
            <Icon icon={icon} {...props} />
            {children} {active && <StyledAct />}
        </LeftWrapper>
    );
};

const Center = ({ text, ...props }) => {
    return <CenterText {...props}>{text}</CenterText>;
};

const HeaderIcon = ({ count, active, icon, ...props }) => {
    return (
        <StyledIcon icon={icon} {...props} />
    );
};


const textStyles = css`
  line-height: 1;
  letter-spacing: normal;
  color: #212223;
  font-size: 14px;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 6px 16px;
  height: 52px;
`;

const LeftWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${textStyles}
`;

const StyledAct = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: solid 2px #fff;
  background-color: #fb4d44;
`;

const RightWrapper = styled.div`
  margin-left: auto;
`;

const Title = styled.div`
  ${textStyles};
  font-size: 17px;
  font-weight: bold;
  color: #434446;
`;

const Text = styled.h1`
  ${textStyles}
`;

const CenterText = styled(Text)`
  position: absolute;
  right: 0;
  left: 50%;
  transform: translateX(-50%);

  align-items: center;
  display: flex;
  justify-content: center;


  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${({theme}) => theme.fg.white};
`;

const StyledIcon = styled(Icon)`
  margin: 0;
  padding: 0;
`;

Header.Center = Center;
Header.Left = Left;
Header.Right = RightWrapper;
Header.Icon = HeaderIcon;

export default Header;
