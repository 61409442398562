import React from "react";
import styled from "@emotion/styled";

function SearchList({ code, onClick, lists, ...props }) {
  if (code) {
    return (
      <Wrapper {...props}>
        {lists &&
          lists.map((list, i) => {
            const unSearchedCode = list.name.slice(
              list.name.indexOf(code) + code.length
            );

            //10 을 검색했을때, 010인 데이터 노출x
            const before = list.name.slice(0, list.name.indexOf(code));

            const isCompletedCode = list.name === code;

            if (!before && (isCompletedCode || unSearchedCode)) {
              return (
                <li key={i} onClick={() => onClick(list.data)}>
                  <b>{code}</b>
                  {unSearchedCode}
                </li>
              );
            }
          })}
      </Wrapper>
    );
  }
}

const Wrapper = styled.ul`
  z-index: 9;

  margin-top: 0;
  overflow: scroll;
  position: absolute;
  top: 52px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  width: 100%;

  height: auto;
  max-height: 248px;

  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  li {
    :first-of-type {
      margin-top: 8px;
    }
    :last-of-type {
      margin-bottom: 8px;
    }
    text-align: start;

    width: 100%;
    height: 52px;
    padding: 13px 16px;

    font-weight: 400;
    font-size: 16px;
    line-height: 160%;

    b {
      color: ${({ theme }) => theme.fg.secondary};
    }
  }
`;

export default SearchList;
