const fg = {
  primary: "#003580",
  secondary: "#0086E5",
  translucent: "#BBBBBB",
  white: "#FFFFFF",
  black: "#222222",
  gray: "#888888",
  danger: "#fb4d44"
};

const bg = {
  primary: "#002559",
  secondary: "#0086E5",
  translucent: "#DDDDDD",
};

const button = {
  primary: "#003580",
  secondary: "#0086E5",
  translucent: "#DDDDDD",
  negative: "#BBBBBB",
};

const container = "0 18px";

const theme = {
  fg,
  bg,
  container,
  button
};

export default theme;
