import React from "react";
import styled from "@emotion/styled";

function SelectList({ onClick, lists, beforeValue, ...props }) {
  return (
    <Wrapper {...props}>
      {lists &&
        lists.map((list, i) => {
          return (
            <li
              key={i}
              onClick={() => onClick(list)}
              className={beforeValue === list.name ? "before" : ""}
            >
              {list.name}
            </li>
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.ul`
  z-index: 9;
  margin-top: 0;
  overflow: scroll;
  position: absolute;
  top: 52px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;

  width: 100%;
  height: auto;
  max-height: 248px;

  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  li {
    text-align: start;
    width: 100%;
    height: 52px;
    padding: 13px 16px;

    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    &.before {
      color: #0086e5;
    }

    b {
      color: ${({ theme }) => theme.fg.secondary};
    }
  }
`;

export default SelectList;
