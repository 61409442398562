import React from 'react';

import LoginTemplate from "../components/templates/LoginTemplate";


function Login(props) {
    return (
        <LoginTemplate/>
    );
}


export default Login;