import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import BarcodeDetector from "barcode-detector-polyfill";

import Protector from "./Protector";

if (!("BarcodeDetector" in window)) {
  window.BarcodeDetector = BarcodeDetector;
}

function BarcodeContent(props) {
  const ref = useRef(null);
  const [videoHeight, setVideoHeight] = useState(0);
  const { BarcodeDetector } = window;

  useEffect(() => {
    if ("BarcodeDetector" in window) {
      if (ref.current) {
        const videoWidth = ref.current.getBoundingClientRect().height;
        setVideoHeight(videoWidth);
      }
      detect();
    } else {
      alert("Barcode Detector is not supported in this browser");
    }
  }, []);

  async function detect() {
    const barcodeDetector = new BarcodeDetector();
    let itemsFound = [];
    const getDevice = await navigator.mediaDevices
      .enumerateDevices()
      .then((res) => {
        return res;
      });

    const isAndroidBackBottomCamera = getDevice.filter((device) =>
      device.label.includes("camera2 0")
    )[0]?.deviceId;
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: isAndroidBackBottomCamera
        ? { deviceId: isAndroidBackBottomCamera }
        : {
            facingMode: "environment",
          },
    });

    const video = ref.current;
    video.srcObject = mediaStream;
    video.autoplay = true;

    async function render() {
      try {
        const barcodes = await barcodeDetector.detect(video);
        barcodes.map((barcode) => {
          const codeNumber = barcode.rawValue;
          if (!itemsFound.includes(codeNumber)) {
            if (codeNumber) {
              window.location.href = `/?code=${codeNumber}`;
            }
            // itemsFound.push(barcode.rawValue);
            // const li = document.createElement("li");
            // li.innerHTML = barcode.rawValue;
            // video.after(li);
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    setInterval(() => {
      render();
    }, 100);

    // (function renderLoop() {
    //     requestAnimationFrame(renderLoop);
    // })();
  }

  return (
    <React.Fragment>
      <Wrapper>
        <p>
          사각테두리 안에 바코드를 <br />
          맞추면 자동으로 인식합니다.
        </p>
        <ScannerContainer>
          <ScannerWrapper>
            <video ref={ref} playsInline={true} />
            <Protector position="left-top" />
            <Protector position="right-top" />
            <Protector position="left-bottom" />
            <Protector position="right-bottom" />
          </ScannerWrapper>
        </ScannerContainer>
      </Wrapper>
      <Bg />
    </React.Fragment>
  );
}

const Bg = styled.div`
  position: fixed;
  top: 52px;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100%;
`;

const Wrapper = styled.div`
  margin: 54px 16px 0;
  position: relative;
  z-index: 1;

  p {
    color: ${({ theme }) => theme.fg.white};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 160%;
  }
`;

const ScannerContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const ScannerWrapper = styled.div`
  padding-top: 100%;

  video {
    //데스크탑 정사각
    //height: 100%;

    //모바일 정사각
    width: 100%;
    height: auto;

    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }
`;

export default BarcodeContent;
