import React from "react";
import styled from "@emotion/styled";

import LabelRadio from "../LabelRadio";

//isEventDisabled : 상품판매에서 location 부천,광교 등등 일때 disabled 체크용
//isLevel2 : level 1, 2일때는 직접입력 disabled
function LabelRadioGroup({
  defaultChecked,
  name,
  lists,
  onChange,
  isEventDisabled,
  isDirectDisabled,
  isLevel2,
  type,
}) {
  return (
    <Wrapper>
      {lists.map((list, idx) => (
        <li key={idx}>
          {list.value === defaultChecked}
          <LabelRadio
            checked={list.label === defaultChecked}
            id={list.id}
            label={list.label}
            name={name}
            onChange={onChange}
            value={list.value}
            disabled={
              isLevel2 && type === "행사" && list.label === "직접입력"
                ? false
                : (isEventDisabled && list.label === "행사가") ||
                  (isLevel2 && list.label === "직접입력") ||
                  (isDirectDisabled && list.label === "직접입력")
                ? true
                : false
            }
          />
        </li>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.ul`
  margin: 0 0 16px 0;
  padding: 0;
  display: flex;
  li {
    padding: 6px 16.5px;
  }
`;

export default LabelRadioGroup;
