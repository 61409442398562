import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@emotion/react";
import GlobalStyles from "./assets/styles/GlobalStyles";
import theme from "./assets/styles/theme";

import { SWRConfig } from "swr";
import { customAxios } from "./api";

const swrConfig = {
  fetcher(url, params) {
    return customAxios.get(url, { params }).then((res) => {
      return res.data?.data || res.data;
    });
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SWRConfig value={swrConfig}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
