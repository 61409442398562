import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import useAxios from "../hooks/useAxios";

import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Icon from "../atoms/Icon";
import Spinner from "../atoms/Spinner";

import Logo from "../../assets/images/Logo-barcode.png";

function LoginTemplate(props) {
  const ref = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const prevUrl = location.state;
  const axios = useAxios();

  const [isCheckedScrollTop, setIsCheckedScrollTop] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [formDataError, setFormDataError] = useState({
    emailError: false,
    passwordError: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [visiblePassword, setVisiblePassword] = useState(true);

  const { email, password } = formData;
  const { emailError, passwordError } = formDataError;

  const isDisabled = email && password ? false : true;

  const handleChange = (text) => (e) => {
    setFormData({
      ...formData,
      [text]: e.target.value,
    });
  };

  async function handleSubmit() {
    try {
      setIsLoading(true);
      setFormDataError({
        emailError: false,
        passwordError: false,
      });

      if (email && password) {
        const { data } = await axios.post(`/barcode/login`, formData);
        if (!data.success) {
          setIsLoading(false);
          window.alert(data.alert);
          setFormDataError({
            emailError: true,
            passwordError: true,
          });

          setTimeout(() => {
            setFormDataError({
              emailError: false,
              passwordError: false,
            });
          }, 1500);
        } else {
          localStorage.setItem("accessToken", data?.data?.token?.access_token);
          localStorage.setItem("authUser", true);
          localStorage.setItem("level", data?.data?.level);
          window.location.href = "/";
        }
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleScroll(e) {
    const target = e.target;
    const { scrollTop } = target;
    if (scrollTop === 0 || scrollTop === 2) {
      target.scrollTo(0, 1);
      setIsCheckedScrollTop(true);
    }
  }

  useEffect(() => {
    const current = ref.current;
    if (current && !isCheckedScrollTop) {
      current.addEventListener("scroll", handleScroll);

      return () => {
        try {
          current.removeEventListener("scroll", handleScroll);
        } catch (e) {
          console.log(e);
        }
      };
    }
  }, [ref]);

  return (
    <Container ref={ref}>
      <Content>
        <LogoWrapper>
          <img src={Logo} />
          <h1>Barcode Reader</h1>
        </LogoWrapper>

        <IconWrapper
          onClick={() => window.open("https://admin.mall.fifteenh.io/")}
        >
          <Icon width={64} icon="login_icon" />
          <h1>쇼핑몰 관리자</h1>
        </IconWrapper>
        <Form>
          <Input
            invalid={emailError}
            placeholder="아이디(이메일)"
            value={email}
            onChange={handleChange("email")}
          />
          <PasswordInput>
            <Input
              type={visiblePassword ? "password" : "text"}
              invalid={passwordError}
              placeholder="비밀번호"
              value={password}
              onChange={handleChange("password")}
            />
            <div onClick={(e) => setVisiblePassword(!visiblePassword)}>
              <Icon
                className="password"
                icon={visiblePassword ? "visible" : "hide"}
              />
            </div>
          </PasswordInput>
        </Form>
      </Content>

      <Footer>
        <Button
          color="secondary"
          type="button"
          disabled={isDisabled || isLoading}
          onClick={handleSubmit}
        >
          로그인
        </Button>
      </Footer>
      {isLoading && <Spinner />}
      <div id="make-scrollable"></div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;

  height: calc(var(--vh, 1vh) * 100);

  background: ${({ theme }) => theme.bg.primary};
  padding: 80px 28px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  #make-scrollable {
    top: 0;
    position: absolute;
    left: 0;
    width: 1px;
    height: calc(
      100% + 2px
    ); // height를 100%보다 1px높게 잡아 실제로 scroll이 되도록 만듭니다.
  }
`;

const Content = styled.div`
  width: 100%;
  //height: 100%;
  //height: calc(100% - 52px);
  overflow-y: scroll;
  text-align: center;
`;

const Footer = styled.div`
  width: 100%;
  height: 52px;
  position: relative;
`;

const LogoWrapper = styled.div`
  height: 104px;
  padding: 10px 0 24px;

  h1 {
    color: ${({ theme }) => theme.fg.white};
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
  }
`;

const IconWrapper = styled.div`
  height: 144px;
  padding: 10px 0 24px;
  gap: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: ${({ theme }) => theme.fg.white};
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
  }
`;

const PasswordInput = styled.div`
  position: relative;

  .password {
    position: absolute;
    top: 0;
    bottom: 0px;
    margin: auto;
    right: 12px;
  }
`;

const Form = styled.form`
  display: grid;
  gap: 16px;
`;

export default LoginTemplate;
