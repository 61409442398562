import React from 'react';
import { useNavigate } from "react-router-dom";

import MainTemplate from "../components/templates/MainTemplate";
import Header from "../components/organisms/Header";
import BarcodeContent from "../components/organisms/BarcodeContent";


function Barcode(props) {
    const navigate = useNavigate();
    return (
        <MainTemplate
            header={
                <Header>
                    <Header.Left
                        icon="arrow-back"
                        width={40}
                        onClick={() => navigate("/")}
                    />
                    <Header.Center text="바코드 촬영" />
                </Header>
            }
        >
            <BarcodeContent/>
        </MainTemplate>
    );
}

export default Barcode;