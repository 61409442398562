import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Button = ({type, ...props}) => {
    const { to, href } = props;

    if(to) {
        return <StyledLink {...props}/>
    }

    if(href) {
        return <Anchor {...props}/>
    }
    return <StyledButton {...props} type={type}/>
};

const styles = props => css`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  //font-size: ${props.height && `${(props.height / 40)}rem`};
  //padding: 0 1em;



  font-size: 18px;
  font-weight: 500;
  padding: 11.5px 12px;
  width: 100%;
  
  

  height: 52px;
  justify-content: center;
  text-decoration: none;
  cursor: ${props.disabled ? "default": "cursor"};
  appearance: none;
  pointer-events: ${props.disabled ? "none": "auto"};
  border-radius: 100px;
  border: ${!props.color? "0.0625em solid #d2d3d5" : "none" };
  transition: background-color 250ms ease-out, color 250ms ease-out, border-color 250ms ease-out;
  background-color: ${
    !props.color
            ? "transparent" 
            : (props.disabled ? props.theme.button.translucent : props.theme.button[props.color])
  };
  color: ${props.theme.fg.white};
 
  svg {
    appearance: none;
  }
`;

const StyledButton = styled.button`${styles}`;
const Anchor = styled.a`${styles}`;
const StyledLink = styled(
    ({
         disabled, transparent, height, theme, ...props
    }) => <Link {...props}/>
)`${styles}`

Button.propTypes = {
    disabled: PropTypes.bool,
    transparent: PropTypes.bool,
    height: PropTypes.number,
    type: PropTypes.string,
    to: PropTypes.string,
    href: PropTypes.string,
    color: PropTypes.oneOf(["primary", "secondary", "negative"])
};

Button.defaultProps = {
    type: 'button',
    height: 52,
};

export default Button;
