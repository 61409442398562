import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useSWR from "swr";
import { customAxios } from "../api";

import MainTemplate from "../components/templates/MainTemplate";
import Header from "../components/organisms/Header";
import Footer from "../components/organisms/Footer";
import EditingContent from "../components/organisms/EditingContent";
import Input from "../components/atoms/Input";

export const PRODUCT_LOCATION_LIST = [
  { text: "부천점", value: "부천점" },
  { text: "광교점", value: "광교점" },
  { text: "반포점", value: "반포점" },
  { text: "인천점", value: "인천점" },
  { text: "부천물류창고", value: "부천물류창고" },
];

function Editing(props) {
  const { code } = useParams();
  const navigate = useNavigate();
  const [searchedProduct, setSearchedProduct] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    event_price: "",
    product_location: "",
  });

  const { event_price, product_location } = formData;

  const { data } = useSWR(`/barcode/product?sku_code=${code}`);
  const product = data?.product;
  const [locationLists, setLocationLists] = useState([]);

  const { data: locationListData } = useSWR(`/barcode/setting/location`);

  useEffect(() => {
    if (locationListData && locationLists.length === 0) {
      PRODUCT_LOCATION_LIST?.map((item, i) => {
        setLocationLists((prev) => {
          return [...prev, { text: item.text, value: item.value }];
        });
      });

      locationListData?.locations?.map((item, i) => {
        setLocationLists((prev) => {
          return [...prev, { text: item.location, value: item.location }];
        });
      });
    }
  }, [locationLists.length, locationListData]);

  useEffect(() => {
    if (product) {
      if (product?.is_sale === 1) {
        window.alert("품절 처리된 상품 입니다.");
        return navigate("/");
      }
      setDefaultData();
    }
  }, [product]);

  function setDefaultData() {
    setSearchedProduct(product);
    setFormData({
      ...formData,
      id: product.id,
      event_price: product?.event_price?.format(),
      product_location: product.product_location,
    });
  }

  const onChange = (text) => (e) => {
    setFormData({
      ...formData,
      [text]: e.target.value,
    });
  };

  const onSelect = (text) => (e) => {
    setFormData({
      ...formData,
      [text]: e.name,
    });
  };

  async function onSubmit() {
    try {
      let event = String(event_price).includes(",");
      const numberTypePrice = event
        ? String(event_price.replaceAll(",", ""))
        : event_price;

      const { data } = await customAxios.post(`/barcode/modify`, {
        ...formData,
        event_price: numberTypePrice,
      });

      if (data) {
        const { success, alert } = data;
        if (success) {
          window.alert("수정이 완료되었습니다.");
        } else {
          window.alert(alert);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <MainTemplate
      header={
        <Header>
          <Header.Left
            icon="arrow-back"
            width={40}
            onClick={(e) => navigate("/")}
          />
          <Header.Center text="상품수정" />
        </Header>
      }
      footer={
        <Footer>
          <Footer.Button
            color="negative"
            type="button"
            onClick={(e) => navigate(`/?code=bu802534`)}
          >
            취소
          </Footer.Button>
          <Footer.Button color="secondary" type="button" onClick={onSubmit}>
            수정
          </Footer.Button>
        </Footer>
      }
      contentHeader={true}
      hideBarcode={true}
    >
      <Input style={{ marginBottom: "16px" }} disabled={true} value={code} />

      <EditingContent
        data={searchedProduct}
        locationLists={locationLists}
        onInputChange={onChange("event_price")}
        inputValue={event_price}
        onSelectChange={onSelect("product_location")}
        selectValue={product_location}
      />
    </MainTemplate>
  );
}

export default Editing;
