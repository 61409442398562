import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";

import Input from "../../atoms/Input";
import Icon from "../../atoms/Icon";

const SearchBar = ({onSearchChange, onSubmit, value, ...props}) => {
    const [term, setTerm] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState(term);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTerm(debouncedTerm)
        }, 500);
        return () => clearTimeout(timer);
    }, [debouncedTerm])

    useEffect(() => {
        onSearchChange(term);

        if(term !== "") {
        } else {

        }
    }, [term])

    useEffect(() => {
        setDebouncedTerm(value)
    }, [value]);



    function onChange(e) {
        setDebouncedTerm(e.target.value)
    }

    return (
        <Wrapper {...props}>
            <Input
                {...props}
                onChange={onChange}
                value={debouncedTerm}
            />
            <SearchIcon icon="input_search" width={24} onClick={e => onSubmit(debouncedTerm)}/>


        </Wrapper>
    );
};

const Wrapper = styled.form`
  position: relative;
  border-radius: 30px;

`;

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0.4444444444em;

  bottom: 0;
  margin: auto;
`;




export default SearchBar;
