import React from 'react';
import styled from "@emotion/styled";

function Protector({ position, ...props }) {
    // if(position === "right") {
    //     return (
    //         <React.Fragment>
    //             <ProtectorItem/>
    //             <ProtectorItem className="vertical"/>
    //         </React.Fragment>
    //     )
    // }
    return (
        <Wrapper className={position}>
            <ProtectorItem/>
            <ProtectorItem className="vertical"/>
        </Wrapper>
    );
}

const ProtectorItem = styled.div`
  position: absolute;
  background: ${({theme}) => theme.fg.white};
  width: calc(100% / 7);
  height: 2px;
  
  &.vertical {
    width: 2px;
    height: calc(100% / 7);
  }
`;

const Wrapper = styled.div`
  &.left-top {
    ${ProtectorItem} {
      left: 0;
      top: 0;
    }
  }
  &.right-top {
    ${ProtectorItem} {
      right: 0;
      top: 0;
    }
  }

  &.left-bottom {
    ${ProtectorItem} {
      left: 0;
      bottom: 0;
    }
  }
  &.right-bottom {
    ${ProtectorItem} {
      right: 0;
      bottom: 0;
    }
  }
`;




export default Protector;