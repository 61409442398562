import React, { useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import Input from "../../atoms/Input";
import Icon from "../../atoms/Icon";
import SelectList from "../../molecules/SelectList";

export const growDown = keyframes`
  0% {
    transform: scaleY(0)
  }
  80% {
    transform: scaleY(1.1)
  }
  100% {
    transform: scaleY(1)
  }
`;

export const growUp = keyframes`
  0% {
    transform: scaleY(1)
  }

  20% {
    transform: scaleY(1.1)
  }
  
  100% {
    transform: scaleY(0)
  }
`;

function Select({ defaultValue, value, lists, onChange, ...props }) {
  const [isActive, setIsActive] = useState(null);

  const showClass = isActive
    ? "show"
    : typeof isActive === "boolean"
    ? "hidden"
    : "";

  return (
    <Wrapper {...props}>
      <InputWrapper>
        <Input
          readOnly
          onClick={(e) => setIsActive(!isActive)}
          value={!value ? defaultValue : value}
        />
        <Icon className="select_icon" icon="dropdown-arrow" width={24} />
      </InputWrapper>
      <StyledList
        className={showClass}
        lists={lists}
        beforeValue={value}
        onClick={(item) => {
          setIsActive(!isActive);
          onChange(item);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div`
  position: relative;

  input {
    padding-right: 44px;
  }

  .select_icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 12px;
  }
`;

const StyledList = styled(SelectList)`
  transform: scale(0);

  &.show {
    transform-origin: top center;
    animation: ${growDown} 0.3s ease-in-out forwards;
  }

  &.hidden {
    transform-origin: top center;
    animation: ${growUp} 0.3s ease-in-out forwards;
  }
`;

export default Select;
