import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";

import useSWR from "swr";
import { customAxios } from "../api";

import MainTemplate from "../components/templates/MainTemplate";
import Header from "../components/organisms/Header";
import Footer from "../components/organisms/Footer";
import Input from "../components/atoms/Input";
import SellingContent from "../components/organisms/SellingContent";
import Search from "../components/organisms/Search";
import { DEFAULT_PRODUCT_LOCATION } from "../utils/variables";

function Selling(props) {
  const { code } = useParams();
  const navigate = useNavigate();
  const [searchedProduct, setSearchedProduct] = useState(null);
  const [searchUser, setSearchUser] = useState("");
  const [clientName, setClientName] = useState("");
  const [send, setSend] = useState(true);
  const [formData, setFormData] = useState({
    product_id: "",
    user_id: "",
    price: "",
    payment_method: "",
    type: "",
    etc: "",
    sale_type: "",
  });

  const { event_price, price, payment_method, etc, sale_type } = formData;

  const { data } = useSWR(`/barcode/product?sku_code=${code}`);

  const product = data?.product;

  const { data: userData } = useSWR(`/barcode/user?search=${searchUser}`);
  const userLists = userData?.users;

  useEffect(() => {
    if (product) {
      if (product?.is_sale === 1) {
        window.alert("품절 처리된 상품 입니다.");
        return navigate("/");
      }
      setDefaultData();
    }
  }, [product]);

  function setDefaultData() {
    setSearchedProduct(product);
    setFormData({
      ...formData,
      product_id: product.id,
      price: product.price,
      type: "판매가",
      sale_type: "매장",
    });
  }

  const onChange = (text) => (e) => {
    if (text === "price") {
      const value = parseInt(
        e.target.value === "" ? "0" : e.target.value.replaceAll(",", "")
      );
      setFormData({
        ...formData,
        [text]: value,
      });
      return;
    }
    setFormData({
      ...formData,
      [text]: e.target.value,
    });
  };

  const onChangeFormData = (newFormData) => {
    setFormData({
      ...formData,
      ...newFormData,
    });
  };

  const onSelect = (text) => (e) => {
    setFormData({
      ...formData,
      [text]: e.value,
    });
  };

  function onListClick(target) {
    setClientName(target.name);
    setFormData({
      ...formData,
      user_id: target.id,
    });
  }

  async function onSubmit() {
    setSend(false);
    const { user_id, price = 10000, payment_method, type } = formData;
    const branch =
      product?.branch_name +
      (product?.branch_name.includes("인천") ||
      product?.branch_name.includes("부천") ||
      product?.branch_name.includes("광교") ||
      product?.branch_name.includes("반포")
        ? "점"
        : "");

    if (!DEFAULT_PRODUCT_LOCATION) {
      window.alert("판매처리 불가한 상품입니다.");
      return;
    }

    if (user_id && price && payment_method && type !== "" && sale_type !== "") {
      try {
        const { data } = await customAxios.post(`/barcode/sale`, formData);

        if (data) {
          const { success, alert } = data;
          if (success) {
            window.alert("판매처리가 완료되었습니다.");
            window.location.href = "/";
          } else {
            window.alert(alert);
          }
        }
      } catch {
      } finally {
        setSend(true);
      }
    } else {
      window.alert("모든 항목을 입력해주세요.");
      setSend(true);
    }
  }

  const listData =
    userLists &&
    userLists?.map((list, i) => {
      const { phone } = list;

      return {
        name: phone,
        data: list,
      };
    });

  return (
    <MainTemplate
      header={
        <Header>
          <Header.Left
            icon="arrow-back"
            width={40}
            onClick={(e) => navigate("/")}
          />
          <Header.Center text="상품판매" />
        </Header>
      }
      footer={
        <Footer>
          <Footer.Button
            color="negative"
            type="button"
            onClick={(e) => navigate(`/?code=bu802534`)}
          >
            취소
          </Footer.Button>
          <Footer.Button
            color="primary"
            type="button"
            onClick={onSubmit}
            disabled={!send}
          >
            판매
          </Footer.Button>
        </Footer>
      }
      contentHeader={true}
      hideBarcode={true}
    >
      <Wrapper>
        <Input style={{ marginBottom: "16px" }} disabled={true} value={code} />

        <Search
          placeholder="고객연락처"
          onSearchChange={(code) => setSearchUser(code)}
          onSubmit={(e) => setClientName(userLists[0].name)}
          lists={listData}
          onListClick={onListClick}
          valueKey="phone"
        />

        <Input
          placeholder="고객명"
          style={{ marginBottom: "16px" }}
          disabled={true}
          value={clientName}
        />

        <SellingContent
          data={searchedProduct}
          onInputChange={onChange}
          onChangeFormData={onChangeFormData}
          inputValue={price}
          onSelectChange={onSelect("payment_method")}
          selectValue={payment_method}
          etcValue={etc}
        />
      </Wrapper>
    </MainTemplate>
  );
}

const Wrapper = styled.div`
  input {
    color: ${({ theme }) => theme.fg.black};
  }
`;

export default Selling;
