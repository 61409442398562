import React, { useState } from "react";
import styled from "@emotion/styled";

import Input from "../../atoms/Input";
import Select from "../Select";
import LabelRadioGroup from "../../molecules/LabelRadioGroup";

function SellingContent({
  data,
  onInputChange,
  inputValue,
  onSelectChange,
  selectValue,
  etcValue,
  onChangeFormData,
  ...props
}) {
  const [selectedRadio, setSelectedRadio] = useState(0);
  const [names, setNames] = useState("판매가");
  const [type, setType] = useState("매장");
  let product = {
    brand_eng: "",
    product_name: "",
    serial_no: "",
    price: "",
    event_price: "",
    product_location: "",
  };

  if (data) {
    product = data;
  }

  const {
    brand_eng,
    product_name,
    serial_no,
    price,
    event_price,
    product_location,
    branch_name,
  } = product;
  const productName = `${brand_eng} | ${product_name} (${serial_no})`;

  const selectData = [
    { value: "card", name: "카드" },
    { value: "cash", name: "현금" },
  ];

  const radioLists = [
    { id: 1, label: "판매가", value: price },
    { id: 2, label: "행사가", value: event_price },
    { id: 3, label: "직접입력", value: 0 },
  ];
  const radioTypeLists = [
    { id: 10, label: "매장" },
    { id: 20, label: "행사" },
    { id: 30, label: "지점위탁" },
  ];

  return (
    <Wrapper {...props}>
      <Input disabled={true} value={productName} />
      {data && (
        <LabelRadioGroup
          defaultChecked={type}
          lists={radioTypeLists}
          name="type"
          onChange={(e) => {
            setType(e.target.getAttribute("label"));

            onChangeFormData({
              sale_type: e.target.getAttribute("label"),
            });
          }}
        />
      )}
      <InputWrapper>
        <span>{names}</span>
        <Input
          name="price"
          disabled={names !== "직접입력"}
          type="comma"
          value={
            names === "행사가" &&
            (Number(selectedRadio) === 0 || !selectedRadio)
              ? "미설정"
              : names !== "직접입력"
              ? selectedRadio
                ? selectedRadio.format()
                : price.format()
              : inputValue
          }
          onChange={onInputChange("price")}
        />
      </InputWrapper>

      {data && (
        <LabelRadioGroup
          defaultChecked={names}
          // labels={labels}
          lists={radioLists}
          name="test"
          onChange={(e) => {
            setNames(e.target.getAttribute("label"));
            setSelectedRadio(e.target.value);

            onChangeFormData({
              type: e.target.getAttribute("label"),
              price: parseInt(e.target.value.replaceAll(",", "")),
            });
          }}
          isLevel2={
            localStorage.getItem("level") === "1" ||
            localStorage.getItem("level") === "2"
          }
          isEventDisabled={
            branch_name === "부천점" ||
            branch_name === "광교점" ||
            branch_name === "반포점" ||
            branch_name === "부천물류창고" ||
            (branch_name !== "부천점" &&
              branch_name !== "광교점" &&
              branch_name !== "반포점" &&
              branch_name !== "부천물류창고" &&
              event_price <= 0)
          }
          isDirectDisabled={false}
          type={type}
        />
      )}

      <Input disabled={true} value={product_location} />

      <Select
        lists={selectData}
        defaultValue="결제 수단"
        onChange={onSelectChange}
        value={
          selectValue === "card" ? "카드" : selectValue === "cash" ? "현금" : ""
        }
      />

      <Input
        placeholder="비고"
        name="etc"
        value={etcValue || ""}
        onChange={onInputChange("etc")}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;

  input {
    margin-bottom: 16px;

    &.last {
      margin-bottom: 24px;
    }
  }
`;

const ImageWrapper = styled.div`
  display: grid;
  h1 {
    text-align: start;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${({ theme }) => theme.fg.black};
    margin-bottom: 8px;
  }
  div {
    overflow: scroll;

    display: flex;
    gap: 8px;
  }
  img {
    opacity: 0.6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;

    position: absolute;
    top: 13px;
    left: 12px;
  }

  input {
    padding-left: 80px;
  }
`;

export default SellingContent;
