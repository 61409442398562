import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import raw from "raw.macro";


const Icon = ({ icon, ...props }) => {
  const svg = raw(`./icons/${icon}.svg`);
  return <Wrapper {...props} dangerouslySetInnerHTML={{ __html: svg }} />;
};

const fontSize = ({ width, height }) => {
  if (width && height) {
    return "0px";
  } else {
    const size = width || height;
    return size ? `${size}px` : "22px";
  }
};

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: sub;
  font-size: ${fontSize && fontSize} !important;

  width: ${(props) =>
    props.width && props.height ? `${props.width}px` : "1em"};
  height: ${(props) =>
    props.width && props.height ? `${props.height}px` : "1em"};
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  svg {
    //fill: currentcolor;
    max-width: 100%;
    max-height: 100%;
  }
`;

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Icon;
