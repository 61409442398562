import React from "react";
import styled from "@emotion/styled";

import Radio from "../../atoms/Radio";

function LabelRadio({
  id,
  label,
  name,
  onChange,
  value,
  checked,
  disabled,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Radio
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        label={label}
        disabled={disabled}
        defaultChecked={checked}
      />
      <label htmlFor={id}>{label}</label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
  }
`;

export default LabelRadio;
