import React from "react";

import Home from "../pages/Home";
import Barcode from "../pages/Barcode";
import Editing from "../pages/Editing";
import Selling from "../pages/Selling";

import Login from "../pages/Login";

const authRoutes = [
  { path: "/", element: Home },
  { path: "/barcode", element: Barcode },
  { path: "/editing/:code", element: Editing },
  { path: "/selling/:code", element: Selling },
];

const unAuthRoutes = [{ path: "/login", element: <Login /> }];

export { authRoutes, unAuthRoutes };
